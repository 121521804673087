const URL = process.env.REACT_APP_API || 'http://localhost:3027/api/';

export const networks = [
	{ name: 'BSC', key: 'bsc', color: 'warning', hex: '#f3ba2f', token: 'BNB' },
	{
		name: 'Matic',
		key: 'matic',
		color: 'info',
		hex: '#8247e5',
		token: 'MATIC'
	},
	{
		name: 'Ethereum',
		key: 'eth',
		color: 'primary',
		hex: '#0090e7',
		token: 'ETH'
	},
	{
		name: 'Arbitrum',
		key: 'arbitrum',
		color: 'success',
		hex: '#00d25b',
		token: 'ETH'
	},
	{
		name: 'Base',
		key: 'base',
		color: 'secondary',
		hex: '#0956f6',
		token: 'ETH'
	}
];

async function wrappedFetch(url, options) {
	const res = await fetch(url, {
		mode: 'cors',
		credentials: 'include',
		...options
	});

	if ([401, 403].includes(res.status)) {
		throw new Error(`Unauthorized: ${res.status}`);
	}

	return res.json();
}

export function getWallets() {
	return wrappedFetch(URL + 'wallet');
}

export function getMainWallet() {
	return wrappedFetch(URL + 'wallet/main');
}

export function getWalletBalances(index, network) {
	return wrappedFetch(`${URL}wallet/${index}/balances/${network}`);
}

export function fund(index, network, amount, minBalance) {
	return wrappedFetch(`${URL}wallet/${index}/fund/${network}`, {
		method: 'put',
		headers: { 'Content-Type': 'application/json' },
		body: JSON.stringify({
			amount,
			minBalance
		})
	});
}

export function sweep(index, network) {
	return wrappedFetch(`${URL}wallet/${index}/sweep/${network}`, {
		method: 'post',
		headers: { 'Content-Type': 'application/json' }
	});
}

export function getBots(full) {
	const params = new URLSearchParams({
		type: full ? 'full' : undefined
	});

	return wrappedFetch(URL + 'bot?' + params);
}

export function getListings(filters = {}, limit) {
	const params = new URLSearchParams(filters);

	return wrappedFetch(URL + 'listing?' + params, {
		headers: { 'X-Limit': limit }
	});
}

export function getUpcomingListings(limit) {
	return wrappedFetch(URL + 'listing/upcoming', {
		headers: { 'X-Limit': limit }
	});
}

export function getPrice() {
	return wrappedFetch(URL + 'price');
}

export function getTransactions(filters = {}, limit) {
	const params = new URLSearchParams();

	return wrappedFetch(URL + 'listing/transactions?' + params, {
		headers: { 'X-Limit': limit }
	});
}

export function getListing(id) {
	return wrappedFetch(URL + 'listing/' + id);
}

export function getStableList() {
	return wrappedFetch(URL + 'listing/stable');
}

export function addListing(data) {
	return wrappedFetch(`${URL}listing`, {
		method: 'post',
		headers: { 'Content-Type': 'application/json' },
		body: JSON.stringify(data)
	});
}

export function saveListing(id, data) {
	if (!id) {
		return addListing(data);
	}

	return wrappedFetch(`${URL}listing/${id}`, {
		method: 'put',
		headers: { 'Content-Type': 'application/json' },
		body: JSON.stringify(data)
	});
}

export function resetListing(id) {
	return wrappedFetch(`${URL}listing/${id}/reset`, {
		method: 'post',
		headers: { 'Content-Type': 'application/json' }
	});
}

export function withdraw(address, amount) {
	return wrappedFetch(`${URL}bot/${address}/withdraw`, {
		method: 'post',
		headers: { 'Content-Type': 'application/json' },
		body: JSON.stringify({ amount })
	});
}

export function unwrapWETH(address) {
	return wrappedFetch(`${URL}bot/${address}/unwrap`, {
		method: 'post',
		headers: { 'Content-Type': 'application/json' }
	});
}

export function withdrawToken(address, tokenAddress) {
	return wrappedFetch(`${URL}bot/${address}/token/withdraw/${tokenAddress}`, {
		method: 'post',
		headers: { 'Content-Type': 'application/json' }
	});
}

export function getSnipes() {
	return wrappedFetch(URL + 'sniper');
}

export function snipeAction(id, type, data = {}) {
	return wrappedFetch(`${URL}sniper/${id}/${type}`, {
		method: 'post',
		headers: { 'Content-Type': 'application/json' },
		body: JSON.stringify(data)
	});
}

export function getProviders() {
	return wrappedFetch(URL + 'provider');
}

export function reconnectProvider(id) {
	return wrappedFetch(`${URL}provider/reconnect/${id}`, {
		method: 'post',
		headers: { 'Content-Type': 'application/json' }
	});
}

export function disconnectProvider(key) {
	return wrappedFetch(`${URL}provider/disconnect/${key}`, {
		method: 'delete',
		headers: { 'Content-Type': 'application/json' }
	});
}

export function connectProvider(key) {
	return wrappedFetch(`${URL}provider/connect/${key}`, {
		method: 'post',
		headers: { 'Content-Type': 'application/json' }
	});
}

export function getTransactionPool() {
	return wrappedFetch(URL + 'provider/transactions');
}

export function login(email, password) {
	return wrappedFetch(`${URL}user/login`, {
		method: 'post',
		headers: { 'Content-Type': 'application/json' },
		body: JSON.stringify({ email, password })
	});
}

export function me() {
	return wrappedFetch(URL + 'user/me');
}

export function frenpetStats() {
	return wrappedFetch(URL + 'frenpet/stats');
}

export function frenpetDaily() {
	return wrappedFetch(URL + 'frenpet/daily');
}

export function frenpetPets(address = '') {
	return wrappedFetch(URL + 'frenpet/pets/' + address);
}

export function frenpetProxies() {
	return wrappedFetch(URL + 'frenpet/proxies');
}
