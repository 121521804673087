import React from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';

const explorers = {
	matic: 'https://polygonscan.com/address/',
	bsc: 'https://bscscan.com/address/',
	arbitrum: 'https://arbiscan.io/address/',
	eth: 'https://etherscan.io/address/',
	base: 'https://basescan.org/address/'
};

function clipAddress(address) {
	return address.slice(0, 4) + '...' + address.slice(-4);
}

export default function Address(props) {
	let { address, network = 'bsc', clip, clipboard, name } = props;

	if (!address) return '-';

	return (
		<span>
			<a target="_blank" href={`${explorers[network]}${address}`}>
				{name || (clip ? clipAddress(address) : address)}
			</a>{' '}
			{clipboard ? (
				<CopyToClipboard text={address}>
					<i className="mdi mdi-content-copy" style={{ cursor: 'pointer' }} />
				</CopyToClipboard>
			) : null}
		</span>
	);
}
