import React, { Component } from 'react';
import { Dropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Trans } from 'react-i18next';
import {
  getMainWallet,
  getProviders,
  disconnectProvider,
  connectProvider,
  networks
} from '../api';
import { toast } from 'react-toastify';
import Network from '../components/Network';
import Address from '../components/Address';
import Switch from '@mui/material/Switch';
import btcImg from '../../assets/images/bitcoin.png';

class Navbar extends Component {
  state = {};

  constructor() {
    super();

    this.state.wallet = [];
    this.state.providers = this.resetProviders();
  }

  componentDidMount() {
    this.fetchProviders();
    this.fetchMainWallet();
  }

  resetProviders() {
    return networks.reduce((acum, item) => {
      acum[item.key] = {
        key: item.key,
        total: 0,
        online: 0,
        status: false,
        loading: true
      };
      return acum;
    }, {});
  }

  async fetchMainWallet() {
    try {
      const wallet = await getMainWallet();
      this.setState({ wallet });
    } catch (e) {
      toast.error(e.message);
    }
  }

  async fetchProviders() {
    const providers = this.resetProviders();

    try {
      const data = await getProviders();
      for (const item of data) {
        providers[item.network].total++;
        if (item.online) {
          providers[item.network].status = true;
          providers[item.network].online++;
        }
      }

      for (const provider of Object.values(providers)) {
        provider.loading = false;
      }

      this.setState({ providers });
    } catch (e) {
      toast.error(e.message);
    }
  }

  async toggleProvider(key) {
    const { providers } = this.state;
    const status = providers[key].status;
    providers[key].status = !status;
    providers[key].loading = true;
    this.setState({ providers });

    try {
      status ? await disconnectProvider(key) : await connectProvider(key);
    } catch (e) {
      providers[key].status = status;
      toast.error(e.message);
    }
    await this.fetchProviders();
  }

  async reconnectAll() {
    const { providers } = this.state;

    this.setState({ providers: this.resetProviders() });

    await Promise.all(
      Object.values(providers).map(async provider => {
        await disconnectProvider(provider.key).catch(err => {});
        await connectProvider(provider.key).catch(err => {});
      })
    );
    await this.fetchProviders();
  }

  toggleOffcanvas() {
    document.querySelector('.sidebar-offcanvas').classList.toggle('active');
  }
  toggleRightSidebar() {
    document.querySelector('.right-sidebar').classList.toggle('open');
  }

  render() {
    const loadingProviders = Object.values(this.state.providers).some(
      provider => provider.loading
    );
    return (
      <nav className="navbar p-0 fixed-top d-flex flex-row">
        <div className="navbar-brand-wrapper d-flex d-lg-none align-items-center justify-content-center">
          <Link className="navbar-brand brand-logo-mini" to="/">
            <img
              src={require('../../assets/images/logo-mini.svg')}
              alt="logo"
            />
          </Link>
        </div>
        <div className="navbar-menu-wrapper flex-grow d-flex align-items-stretch">
          <button
            className="navbar-toggler align-self-center"
            type="button"
            onClick={() => document.body.classList.toggle('sidebar-icon-only')}
          >
            <span className="mdi mdi-menu"></span>
          </button>
          <ul className="navbar-nav w-100">
            <li className="nav-item w-100">
              <form className="nav-link mt-2 mt-md-0 d-none d-lg-flex search">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search listings"
                />
              </form>
            </li>
          </ul>
          <ul className="navbar-nav navbar-nav-right">
            <li alignRight as="li" className="nav-item d-none d-lg-block">
              <Link
                to="/listing/add"
                className="nav-link btn btn-success create-new-button no-caret"
              >
                + <Trans>Create New Listing</Trans>
              </Link>
            </li>

            <Dropdown alignRight className="border-left nav-item providers">
              <Dropdown.Toggle
                as="a"
                className="cursor-pointer no-caret nav-link"
              >
                <i className="mdi mdi-server text-info"></i>
              </Dropdown.Toggle>
              <Dropdown.Menu className="dropdown-menu navbar-dropdown preview-list">
                <h6 className="p-3 mb-0">
                  <Trans>Providers</Trans>
                  {!loadingProviders && (
                    <span
                      className="float-right"
                      onClick={() => this.fetchProviders()}
                    >
                      <i className="mdi mdi-reload text-primary"></i>
                    </span>
                  )}
                </h6>
                <Dropdown.Divider />
                {networks.map(network => {
                  const provider = this.state.providers[network.key];
                  if (!provider) {
                    // loading
                    return null;
                  }

                  return (
                    <React.Fragment key={network.key}>
                      <a
                        href="!#"
                        className="dropdown-item preview-item"
                        onClick={evt => evt.preventDefault()}
                      >
                        <div className="preview-thumbnail">
                          <Network network={network.key} />
                        </div>
                        <div className="preview-item-content">
                          <p className="preview-subject mb-1">
                            <Trans>{network.name}</Trans>
                          </p>
                          {!provider.loading ? (
                            <p className="text-muted ellipsis mb-0">
                              <Trans>
                                {provider?.online > 0
                                  ? `${provider.online}/${provider.total}`
                                  : 'offline'}
                              </Trans>
                              <span
                                className={`status bg-${
                                  provider?.online ? 'success' : 'danger'
                                }`}
                              ></span>
                            </p>
                          ) : (
                            <p className="text-muted ellipsis mb-0">
                              <Trans>loading...</Trans>
                              <span className={`status bg-warning`}></span>
                            </p>
                          )}
                        </div>
                        <Switch
                          onClick={() =>
                            this.toggleProvider(network.key, provider?.online)
                          }
                          checked={!!provider?.status}
                          size="small"
                        />
                      </a>
                      <Dropdown.Divider />
                    </React.Fragment>
                  );
                })}
                <p className="p-3 mb-0 text-center">
                  {!loadingProviders ? (
                    <span
                      onClick={() => this.reconnectAll()}
                      className="btn btn-sm btn-primary"
                    >
                      Reconnect all
                    </span>
                  ) : (
                    'loading...'
                  )}
                </p>
              </Dropdown.Menu>
            </Dropdown>

            <Dropdown alignRight className="border-left nav-item providers">
              <Dropdown.Toggle
                as="a"
                className="cursor-pointer no-caret nav-link"
              >
                <i className="mdi mdi-wallet text-warning"></i>
              </Dropdown.Toggle>
              <Dropdown.Menu className="dropdown-menu navbar-dropdown preview-list">
                <h6 className="p-3 mb-0">
                  <Trans>Main Wallet</Trans>
                  {!loadingProviders && (
                    <span
                      className="float-right"
                      onClick={() => this.fetchMainWallet()}
                    >
                      <i className="mdi mdi-reload text-primary"></i>
                    </span>
                  )}
                </h6>
                <Dropdown.Divider />
                {this.state.wallet.map(wallet => {
                  return (
                    <React.Fragment key={wallet.network}>
                      <a
                        href="!#"
                        className="dropdown-item preview-item"
                        onClick={evt => evt.preventDefault()}
                      >
                        <div className="preview-thumbnail">
                          <Network network={wallet.network} />
                        </div>
                        <div className="preview-item-content">
                          <p className="preview-subject mb-1">
                            <Trans>
                              {(wallet.balance || '-').substr(0, 4)}
                            </Trans>
                          </p>
                          <p className="text-muted ellipsis mb-0">
                            <Address
                              address={wallet.address}
                              network={wallet.network}
                              clip
                            />
                          </p>
                        </div>
                      </a>
                      <Dropdown.Divider />
                    </React.Fragment>
                  );
                })}
              </Dropdown.Menu>
            </Dropdown>
            <Dropdown alignRight as="li" className="nav-item">
              <Dropdown.Toggle
                as="a"
                className="nav-link cursor-pointer no-caret"
              >
                <div className="navbar-profile">
                  <img
                    className="img-xs rounded-circle"
                    src={btcImg}
                    alt="profile"
                  />
                  <p className="mb-0 d-none d-sm-block navbar-profile-name">
                    <Trans>Marcos C.</Trans>
                  </p>
                  <i className="mdi mdi-menu-down d-none d-sm-block"></i>
                </div>
              </Dropdown.Toggle>

              <Dropdown.Menu className="navbar-dropdown preview-list navbar-profile-dropdown-menu">
                <h6 className="p-3 mb-0">
                  <Trans>Profile</Trans>
                </h6>
                <Dropdown.Divider />
                <Dropdown.Item
                  href="!#"
                  onClick={evt => evt.preventDefault()}
                  className="preview-item"
                >
                  <div className="preview-thumbnail">
                    <div className="preview-icon bg-dark rounded-circle">
                      <i className="mdi mdi-settings text-success"></i>
                    </div>
                  </div>
                  <div className="preview-item-content">
                    <p className="preview-subject mb-1">
                      <Trans>Settings</Trans>
                    </p>
                  </div>
                </Dropdown.Item>
                <Dropdown.Divider />
                <Dropdown.Item
                  href="!#"
                  onClick={evt => evt.preventDefault()}
                  className="preview-item"
                >
                  <div className="preview-thumbnail">
                    <div className="preview-icon bg-dark rounded-circle">
                      <i className="mdi mdi-logout text-danger"></i>
                    </div>
                  </div>
                  <div className="preview-item-content">
                    <p className="preview-subject mb-1">
                      <Trans>Log Out</Trans>
                    </p>
                  </div>
                </Dropdown.Item>
                <Dropdown.Divider />
                <p className="p-3 mb-0 text-center">
                  <Trans>Advanced settings</Trans>
                </p>
              </Dropdown.Menu>
            </Dropdown>
          </ul>
          <button
            className="navbar-toggler navbar-toggler-right d-lg-none align-self-center"
            type="button"
            onClick={this.toggleOffcanvas}
          >
            <span className="mdi mdi-format-line-spacing"></span>
          </button>
        </div>
      </nav>
    );
  }
}

export default Navbar;
