import React, { Component, Suspense, lazy } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import Spinner from '../app/shared/Spinner';

const Dashboard = lazy(() => import('./dashboard/Dashboard'));

const Buttons = lazy(() => import('./basic-ui/Buttons'));
const Dropdowns = lazy(() => import('./basic-ui/Dropdowns'));
const Typography = lazy(() => import('./basic-ui/Typography'));

const BasicElements = lazy(() => import('./form-elements/BasicElements'));

const BasicTable = lazy(() => import('./tables/BasicTable'));

const Mdi = lazy(() => import('./icons/Mdi'));

const ChartJs = lazy(() => import('./charts/ChartJs'));

const Error404 = lazy(() => import('./error-pages/Error404'));
const Error500 = lazy(() => import('./error-pages/Error500'));

const Login = lazy(() => import('./user-pages/Login'));
const Register1 = lazy(() => import('./user-pages/Register'));

const WalletList = lazy(() => import('./wallet/List'));
const ListingList = lazy(() => import('./listing/List'));
const ListingAdd = lazy(() => import('./listing/Add'));
const Snipes = lazy(() => import('./snipes/Dashboard'));
const Terminal = lazy(() => import('./terminal/Terminal'));
const Providers = lazy(() => import('./providers/Dashboard'));
const Transactions = lazy(() => import('./providers/Transactions'));
const Frenpet = lazy(() => import('./frenpet/Frenpet'));
const Pets = lazy(() => import('./frenpet/Pets'));
const FrenpetProxies = lazy(() => import('./frenpet/Proxies'));

class AppRoutes extends Component {
  render() {
    return (
      <Suspense fallback={<Spinner />}>
        <Switch>
          <Route exact path="/dashboard" component={Dashboard} />

          <Route path="/basic-ui/buttons" component={Buttons} />
          <Route path="/basic-ui/dropdowns" component={Dropdowns} />
          <Route path="/basic-ui/typography" component={Typography} />

          <Route
            path="/form-Elements/basic-elements"
            component={BasicElements}
          />

          <Route path="/tables/basic-table" component={BasicTable} />

          <Route path="/icons/mdi" component={Mdi} />

          <Route path="/charts/chart-js" component={ChartJs} />

          <Route path="/login" component={Login} />
          <Route path="/register" component={Register1} />

          <Route path="/error-pages/error-404" component={Error404} />
          <Route path="/error-pages/error-500" component={Error500} />

          <Route path="/wallet/list/:id/:network" component={WalletList} />
          <Route path="/listing/edit/:id" component={ListingAdd} />
          <Route path="/listing/add" component={ListingAdd} />
          <Route path="/listing" component={ListingList} />

          <Route exact path="/snipes" component={Snipes} />
          <Route exact path="/terminal" component={Terminal} />
          <Route exact path="/providers" component={Providers} />
          <Route exact path="/frenpet" component={Frenpet} />
          <Route exact path="/frenpet/pets" component={Pets} />
          <Route exact path="/frenpet/proxies" component={FrenpetProxies} />

          <Route
            exact
            path="/providers/transactions"
            component={Transactions}
          />

          <Redirect to="/dashboard" />
        </Switch>
      </Suspense>
    );
  }
}

export default AppRoutes;
