import React from 'react';

import bsc from '../../assets/images/bsc.png';
import eth from '../../assets/images/eth.png';
import arbitrum from '../../assets/images/arbitrum.png';
import matic from '../../assets/images/matic.png';
import base from '../../assets/images/base.png';

const images = {
	bsc,
	eth,
	arbitrum,
	matic,
	base
};

export default function Network(props) {
	let { network = 'bsc' } = props;

	return (
		<div className="preview-thumbnail">
			<div className="preview-icon">
				<img
					className="img-xs rounded-circle"
					src={images[network]}
					alt="profile"
				/>
			</div>
		</div>
	);
}
