import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Collapse, Dropdown } from 'react-bootstrap';
import { Trans } from 'react-i18next';
import { toast } from 'react-toastify';

import { getWallets, networks } from '../api';
import logoMini from '../../assets/images/logo-mini.svg';
import logo from '../../assets/images/logo.svg';
import btcImg from '../../assets/images/bitcoin.png';

class Sidebar extends Component {
  state = {
    wallets: []
  };

  toggleMenuState(menuState) {
    if (this.state[menuState]) {
      this.setState({ [menuState]: false });
    } else if (Object.keys(this.state).length === 0) {
      this.setState({ [menuState]: true });
    } else {
      Object.keys(this.state).forEach(i => {
        if (i.includes('Menu')) this.setState({ [i]: false });
      });
      this.setState({ [menuState]: true });
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.onRouteChanged();
    }
  }

  onRouteChanged() {
    document.querySelector('#sidebar').classList.remove('active');
    Object.keys(this.state).forEach(i => {
      if (i.includes('Menu')) this.setState({ [i]: false });
    });

    const dropdownPaths = [
      { path: '/apps', state: 'appsMenuOpen' },
      { path: '/basic-ui', state: 'basicUiMenuOpen' },
      { path: '/form-elements', state: 'formElementsMenuOpen' },
      { path: '/tables', state: 'tablesMenuOpen' },
      { path: '/icons', state: 'iconsMenuOpen' },
      { path: '/charts', state: 'chartsMenuOpen' },
      { path: '/user-pages', state: 'userPagesMenuOpen' },
      { path: '/error-pages', state: 'errorPagesMenuOpen' },
      { path: '/wallet', state: 'walletMenuOpen' },
      { path: '/listing', state: 'listingMenuOpen' },
      { path: '/frenpet', state: 'frenpetMenuOpen' },
      { path: '/snipes', state: 'snipesMenuOpen' },
      { path: '/providers', state: 'providersMenuOpen' },
      { path: '/terminal', state: 'terminalMenuOpen' }
    ];

    dropdownPaths.forEach(obj => {
      if (this.isPathActive(obj.path)) {
        this.setState({ [obj.state]: true });
      }
    });
  }

  render() {
    const { wallets = [] } = this.state;

    return (
      <nav className="sidebar sidebar-offcanvas" id="sidebar">
        <div className="sidebar-brand-wrapper d-none d-lg-flex align-items-center justify-content-center fixed-top">
          <a className="sidebar-brand brand-logo" href="index.html">
            <img src={logo} alt="logo" />
          </a>
          <a className="sidebar-brand brand-logo-mini" href="index.html">
            <img src={logoMini} alt="logo" />
          </a>
        </div>
        <ul className="nav">
          <li className="nav-item profile">
            <div className="profile-desc">
              <div className="profile-pic">
                <div className="count-indicator">
                  <img
                    className="img-xs rounded-circle"
                    src={btcImg}
                    alt="profile"
                  />
                  <span className="count bg-success"></span>
                </div>
                <div className="profile-name">
                  <h5 className="mb-0 font-weight-normal">
                    <Trans>Marcos C.</Trans>
                  </h5>
                  <span>
                    <Trans>Admin</Trans>
                  </span>
                </div>
              </div>
              <Dropdown alignRight>
                <Dropdown.Toggle as="a" className="cursor-pointer no-caret">
                  <i className="mdi mdi-dots-vertical"></i>
                </Dropdown.Toggle>
                <Dropdown.Menu className="sidebar-dropdown preview-list">
                  <a
                    href="!#"
                    className="dropdown-item preview-item"
                    onClick={evt => evt.preventDefault()}
                  >
                    <div className="preview-thumbnail">
                      <div className="preview-icon bg-dark rounded-circle">
                        <i className="mdi mdi-settings text-primary"></i>
                      </div>
                    </div>
                    <div className="preview-item-content">
                      <p className="preview-subject ellipsis mb-1 text-small">
                        <Trans>Account settings</Trans>
                      </p>
                    </div>
                  </a>
                  <div className="dropdown-divider"></div>
                  <a
                    href="!#"
                    className="dropdown-item preview-item"
                    onClick={evt => evt.preventDefault()}
                  >
                    <div className="preview-thumbnail">
                      <div className="preview-icon bg-dark rounded-circle">
                        <i className="mdi mdi-onepassword  text-info"></i>
                      </div>
                    </div>
                    <div className="preview-item-content">
                      <p className="preview-subject ellipsis mb-1 text-small">
                        <Trans>Change Password</Trans>
                      </p>
                    </div>
                  </a>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </li>
          <li className="nav-item nav-category">
            <span className="nav-link">
              <Trans>Navigation</Trans>
            </span>
          </li>
          <li
            className={
              this.isPathActive('/dashboard')
                ? 'nav-item menu-items active'
                : 'nav-item menu-items'
            }
          >
            <Link className="nav-link" to="/dashboard">
              <span className="menu-icon">
                <i className="mdi mdi-speedometer"></i>
              </span>
              <span className="menu-title">
                <Trans>Dashboard</Trans>
              </span>
            </Link>
          </li>

          <li
            className={
              this.isPathActive('/wallet')
                ? 'nav-item menu-items active'
                : 'nav-item menu-items'
            }
          >
            <div
              className={
                this.state.walletMenuOpen
                  ? 'nav-link menu-expanded'
                  : 'nav-link'
              }
              onClick={() => this.toggleMenuState('walletMenuOpen')}
              data-toggle="collapse"
            >
              <span className="menu-icon">
                <i className="mdi mdi-cash-usd"></i>
              </span>
              <span className="menu-title">
                <Trans>Wallet</Trans>
              </span>
              <i className="menu-arrow"></i>
            </div>
            <Collapse in={this.state.walletMenuOpen}>
              <div>
                <ul className="nav flex-column sub-menu">
                  {networks.map(network =>
                    wallets.map((wallet, walletIndex) => (
                      <li
                        className="nav-item"
                        key={`${walletIndex}-${network.key}`}
                      >
                        <Link
                          className={
                            this.isPathActive(
                              `/wallet/list/${walletIndex}/${network.key}`
                            )
                              ? 'nav-link active'
                              : 'nav-link'
                          }
                          to={`/wallet/list/${walletIndex}/${network.key}`}
                        >
                          <Trans>
                            {wallet.name} {`[${network.name}]`}
                          </Trans>
                        </Link>
                      </li>
                    ))
                  )}
                </ul>
              </div>
            </Collapse>
          </li>
          <li
            className={
              this.isPathActive('/listing')
                ? 'nav-item menu-items active'
                : 'nav-item menu-items'
            }
          >
            <div
              className={
                this.state.listingMenuOpen
                  ? 'nav-link menu-expanded'
                  : 'nav-link'
              }
              onClick={() => this.toggleMenuState('listingMenuOpen')}
              data-toggle="collapse"
            >
              <span className="menu-icon">
                <i className="mdi mdi-format-list-bulleted"></i>
              </span>
              <span className="menu-title">
                <Trans>Listings</Trans>
              </span>
              <i className="menu-arrow"></i>
            </div>
            <Collapse in={this.state.listingMenuOpen}>
              <div>
                <ul className="nav flex-column sub-menu">
                  <li className="nav-item">
                    {' '}
                    <Link
                      className={
                        this.isPathActive(`/listing`)
                          ? 'nav-link active'
                          : 'nav-link'
                      }
                      to={`/listing`}
                    >
                      <Trans>List</Trans>
                    </Link>
                    <Link
                      className={
                        this.isPathActive(`/listing/add`)
                          ? 'nav-link active'
                          : 'nav-link'
                      }
                      to={`/listing/add`}
                    >
                      <Trans>Add</Trans>
                    </Link>
                  </li>
                </ul>
              </div>
            </Collapse>
          </li>
          <li className="nav-item menu-items">
            <Link
              className={
                this.isPathActive('/bots') ? 'nav-link active' : 'nav-link'
              }
              to="/bots"
            >
              <span className="menu-icon">
                <i className="mdi mdi mdi-laptop"></i>
              </span>
              <span className="menu-title">
                <Trans>Bots</Trans>
              </span>
            </Link>
          </li>
          <li
            className={
              this.isPathActive('/frenpet')
                ? 'nav-item menu-items active'
                : 'nav-item menu-items'
            }
          >
            <div
              className={
                this.state.frenpetMenuOpen
                  ? 'nav-link menu-expanded'
                  : 'nav-link'
              }
              onClick={() => this.toggleMenuState('frenpetMenuOpen')}
              data-toggle="collapse"
            >
              <span className="menu-icon">
                <i className="mdi mdi-dog"></i>
              </span>
              <span className="menu-title">
                <Trans>Frenpet</Trans>
              </span>
              <i className="menu-arrow"></i>
            </div>
            <Collapse in={this.state.frenpetMenuOpen}>
              <div>
                <ul className="nav flex-column sub-menu">
                  <li className="nav-item">
                    <Link
                      className={
                        this.isPathActive(`/providers`)
                          ? 'nav-link active'
                          : 'nav-link'
                      }
                      to={`/frenpet`}
                    >
                      <Trans>Stats</Trans>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      className={
                        this.isPathActive(`/frenpet/proxies`)
                          ? 'nav-link active'
                          : 'nav-link'
                      }
                      to={`/frenpet/proxies`}
                    >
                      <Trans>Proxies</Trans>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      className={
                        this.isPathActive(`/frenpet/pets`)
                          ? 'nav-link active'
                          : 'nav-link'
                      }
                      to={`/frenpet/pets`}
                    >
                      <Trans>Pets</Trans>
                    </Link>
                  </li>
                </ul>
              </div>
            </Collapse>
          </li>
          <li className="nav-item menu-items">
            <Link
              className={
                this.isPathActive('/snipes') ? 'nav-link active' : 'nav-link'
              }
              to="/snipes"
            >
              <span className="menu-icon">
                <i className="mdi mdi-format-list-bulleted"></i>
              </span>
              <span className="menu-title">
                <Trans>Snipes</Trans>
              </span>
            </Link>
          </li>
          <li
            className={
              this.isPathActive('/providers')
                ? 'nav-item menu-items active'
                : 'nav-item menu-items'
            }
          >
            <div
              className={
                this.state.providersMenuOpen
                  ? 'nav-link menu-expanded'
                  : 'nav-link'
              }
              onClick={() => this.toggleMenuState('providersMenuOpen')}
              data-toggle="collapse"
            >
              <span className="menu-icon">
                <i className="mdi mdi-server"></i>
              </span>
              <span className="menu-title">
                <Trans>Providers</Trans>
              </span>
              <i className="menu-arrow"></i>
            </div>
            <Collapse in={this.state.providersMenuOpen}>
              <div>
                <ul className="nav flex-column sub-menu">
                  <li className="nav-item">
                    <Link
                      className={
                        this.isPathActive(`/providers`)
                          ? 'nav-link active'
                          : 'nav-link'
                      }
                      to={`/providers`}
                    >
                      <Trans>Providers</Trans>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      className={
                        this.isPathActive(`/providers/transactions`)
                          ? 'nav-link active'
                          : 'nav-link'
                      }
                      to={`/providers/transactions`}
                    >
                      <Trans>Transaction Pool</Trans>
                    </Link>
                  </li>
                </ul>
              </div>
            </Collapse>
          </li>
          <li className="nav-item menu-items">
            <Link
              className={
                this.isPathActive('/terminal') ? 'nav-link active' : 'nav-link'
              }
              to="/terminal"
            >
              <span className="menu-icon">
                <i className="mdi mdi-console"></i>
              </span>
              <span className="menu-title">
                <Trans>Terminal</Trans>
              </span>
            </Link>
          </li>
          <li className="nav-item nav-category">
            <span className="nav-link">
              <Trans>More</Trans>
            </span>
          </li>

          <li
            className={
              this.isPathActive('/basic-ui')
                ? 'nav-item menu-items active'
                : 'nav-item menu-items'
            }
          >
            <div
              className={
                this.state.basicUiMenuOpen
                  ? 'nav-link menu-expanded'
                  : 'nav-link'
              }
              onClick={() => this.toggleMenuState('basicUiMenuOpen')}
              data-toggle="collapse"
            >
              <span className="menu-icon">
                <i className="mdi mdi-laptop"></i>
              </span>
              <span className="menu-title">
                <Trans>Basic UI Elements</Trans>
              </span>
              <i className="menu-arrow"></i>
            </div>
            <Collapse in={this.state.basicUiMenuOpen}>
              <div>
                <ul className="nav flex-column sub-menu">
                  <li className="nav-item">
                    {' '}
                    <Link
                      className={
                        this.isPathActive('/basic-ui/buttons')
                          ? 'nav-link active'
                          : 'nav-link'
                      }
                      to="/basic-ui/buttons"
                    >
                      <Trans>Buttons</Trans>
                    </Link>
                  </li>
                  <li className="nav-item">
                    {' '}
                    <Link
                      className={
                        this.isPathActive('/basic-ui/dropdowns')
                          ? 'nav-link active'
                          : 'nav-link'
                      }
                      to="/basic-ui/dropdowns"
                    >
                      <Trans>Dropdowns</Trans>
                    </Link>
                  </li>
                  <li className="nav-item">
                    {' '}
                    <Link
                      className={
                        this.isPathActive('/basic-ui/typography')
                          ? 'nav-link active'
                          : 'nav-link'
                      }
                      to="/basic-ui/typography"
                    >
                      <Trans>Typography</Trans>
                    </Link>
                  </li>
                </ul>
              </div>
            </Collapse>
          </li>
          <li
            className={
              this.isPathActive('/form-elements')
                ? 'nav-item menu-items active'
                : 'nav-item menu-items'
            }
          >
            <div
              className={
                this.state.formElementsMenuOpen
                  ? 'nav-link menu-expanded'
                  : 'nav-link'
              }
              onClick={() => this.toggleMenuState('formElementsMenuOpen')}
              data-toggle="collapse"
            >
              <span className="menu-icon">
                <i className="mdi mdi-playlist-play"></i>
              </span>
              <span className="menu-title">
                <Trans>Form Elements</Trans>
              </span>
              <i className="menu-arrow"></i>
            </div>
            <Collapse in={this.state.formElementsMenuOpen}>
              <div>
                <ul className="nav flex-column sub-menu">
                  <li className="nav-item">
                    {' '}
                    <Link
                      className={
                        this.isPathActive('/form-elements/basic-elements')
                          ? 'nav-link active'
                          : 'nav-link'
                      }
                      to="/form-elements/basic-elements"
                    >
                      <Trans>Basic Elements</Trans>
                    </Link>
                  </li>
                </ul>
              </div>
            </Collapse>
          </li>
          <li
            className={
              this.isPathActive('/tables')
                ? 'nav-item menu-items active'
                : 'nav-item menu-items'
            }
          >
            <div
              className={
                this.state.tablesMenuOpen
                  ? 'nav-link menu-expanded'
                  : 'nav-link'
              }
              onClick={() => this.toggleMenuState('tablesMenuOpen')}
              data-toggle="collapse"
            >
              <span className="menu-icon">
                <i className="mdi mdi-table-large"></i>
              </span>
              <span className="menu-title">
                <Trans>Tables</Trans>
              </span>
              <i className="menu-arrow"></i>
            </div>
            <Collapse in={this.state.tablesMenuOpen}>
              <div>
                <ul className="nav flex-column sub-menu">
                  <li className="nav-item">
                    {' '}
                    <Link
                      className={
                        this.isPathActive('/tables/basic-table')
                          ? 'nav-link active'
                          : 'nav-link'
                      }
                      to="/tables/basic-table"
                    >
                      <Trans>Basic Table</Trans>
                    </Link>
                  </li>
                </ul>
              </div>
            </Collapse>
          </li>
          <li
            className={
              this.isPathActive('/charts')
                ? 'nav-item menu-items active'
                : 'nav-item menu-items'
            }
          >
            <div
              className={
                this.state.chartsMenuOpen
                  ? 'nav-link menu-expanded'
                  : 'nav-link'
              }
              onClick={() => this.toggleMenuState('chartsMenuOpen')}
              data-toggle="collapse"
            >
              <span className="menu-icon">
                <i className="mdi mdi-chart-bar"></i>
              </span>
              <span className="menu-title">
                <Trans>Charts</Trans>
              </span>
              <i className="menu-arrow"></i>
            </div>
            <Collapse in={this.state.chartsMenuOpen}>
              <div>
                <ul className="nav flex-column sub-menu">
                  <li className="nav-item">
                    {' '}
                    <Link
                      className={
                        this.isPathActive('/charts/chart-js')
                          ? 'nav-link active'
                          : 'nav-link'
                      }
                      to="/charts/chart-js"
                    >
                      <Trans>Chart Js</Trans>
                    </Link>
                  </li>
                </ul>
              </div>
            </Collapse>
          </li>
          <li
            className={
              this.isPathActive('/icons')
                ? 'nav-item menu-items active'
                : 'nav-item menu-items'
            }
          >
            <div
              className={
                this.state.iconsMenuOpen ? 'nav-link menu-expanded' : 'nav-link'
              }
              onClick={() => this.toggleMenuState('iconsMenuOpen')}
              data-toggle="collapse"
            >
              <span className="menu-icon">
                <i className="mdi mdi-contacts"></i>
              </span>
              <span className="menu-title">
                <Trans>Icons</Trans>
              </span>
              <i className="menu-arrow"></i>
            </div>
            <Collapse in={this.state.iconsMenuOpen}>
              <div>
                <ul className="nav flex-column sub-menu">
                  <li className="nav-item">
                    {' '}
                    <Link
                      className={
                        this.isPathActive('/icons/mdi')
                          ? 'nav-link active'
                          : 'nav-link'
                      }
                      to="/icons/mdi"
                    >
                      <Trans>Material</Trans>
                    </Link>
                  </li>
                </ul>
              </div>
            </Collapse>
          </li>
          <li
            className={
              this.isPathActive('/user-pages')
                ? 'nav-item menu-items active'
                : 'nav-item menu-items'
            }
          >
            <div
              className={
                this.state.userPagesMenuOpen
                  ? 'nav-link menu-expanded'
                  : 'nav-link'
              }
              onClick={() => this.toggleMenuState('userPagesMenuOpen')}
              data-toggle="collapse"
            >
              <span className="menu-icon">
                <i className="mdi mdi-security"></i>
              </span>
              <span className="menu-title">
                <Trans>User Pages</Trans>
              </span>
              <i className="menu-arrow"></i>
            </div>
            <Collapse in={this.state.userPagesMenuOpen}>
              <div>
                <ul className="nav flex-column sub-menu">
                  <li className="nav-item">
                    {' '}
                    <Link
                      className={
                        this.isPathActive('/user-pages/login-1')
                          ? 'nav-link active'
                          : 'nav-link'
                      }
                      to="/user-pages/login-1"
                    >
                      <Trans>Login</Trans>
                    </Link>
                  </li>
                  <li className="nav-item">
                    {' '}
                    <Link
                      className={
                        this.isPathActive('/user-pages/register-1')
                          ? 'nav-link active'
                          : 'nav-link'
                      }
                      to="/user-pages/register-1"
                    >
                      <Trans>Register</Trans>
                    </Link>
                  </li>
                </ul>
              </div>
            </Collapse>
          </li>
          <li
            className={
              this.isPathActive('/error-pages')
                ? 'nav-item menu-items active'
                : 'nav-item menu-items'
            }
          >
            <div
              className={
                this.state.errorPagesMenuOpen
                  ? 'nav-link menu-expanded'
                  : 'nav-link'
              }
              onClick={() => this.toggleMenuState('errorPagesMenuOpen')}
              data-toggle="collapse"
            >
              <span className="menu-icon">
                <i className="mdi mdi-lock"></i>
              </span>
              <span className="menu-title">
                <Trans>Error Pages</Trans>
              </span>
              <i className="menu-arrow"></i>
            </div>
            <Collapse in={this.state.errorPagesMenuOpen}>
              <div>
                <ul className="nav flex-column sub-menu">
                  <li className="nav-item">
                    {' '}
                    <Link
                      className={
                        this.isPathActive('/error-pages/error-404')
                          ? 'nav-link active'
                          : 'nav-link'
                      }
                      to="/error-pages/error-404"
                    >
                      404
                    </Link>
                  </li>
                  <li className="nav-item">
                    {' '}
                    <Link
                      className={
                        this.isPathActive('/error-pages/error-500')
                          ? 'nav-link active'
                          : 'nav-link'
                      }
                      to="/error-pages/error-500"
                    >
                      500
                    </Link>
                  </li>
                </ul>
              </div>
            </Collapse>
          </li>
        </ul>
      </nav>
    );
  }

  isPathActive(path) {
    return this.props.location.pathname.startsWith(path);
  }

  componentDidMount() {
    getWallets()
      .then(wallets => {
        this.setState({ wallets });
      })
      .catch(e => toast.error(e.message));

    this.onRouteChanged();
    // add class 'hover-open' to sidebar navitem while hover in sidebar-icon-only menu
    const body = document.querySelector('body');
    document.querySelectorAll('.sidebar .nav-item').forEach(el => {
      el.addEventListener('mouseover', function () {
        if (body.classList.contains('sidebar-icon-only')) {
          el.classList.add('hover-open');
        }
      });
      el.addEventListener('mouseout', function () {
        if (body.classList.contains('sidebar-icon-only')) {
          el.classList.remove('hover-open');
        }
      });
    });
  }
}

export default withRouter(Sidebar);
